import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import { aegis } from '@/common/aegis'
import { Toast } from '@/components/Toast'
import { httpClient } from '@/common/http-client/index'
export interface CaptchaProps {
  randstr: string
  ticket: string
}
interface Props {
  params: { id: string; seqid: string }
  callback?: (type: 'success' | 'close', props?: CaptchaProps) => void
}

const checkCaptcha = async (res: any, seqid: string) => {
  const captchaResult = []
  captchaResult.push(res.ret)
  if (res.ret === 0) {
    captchaResult.push(res.ticket)
    captchaResult.push(res.randstr)
    captchaResult.push(seqid)
  }
  const content = captchaResult.join('\n')

  const fetchCaptcha = httpClient.registerApi({
    url: `${String(import.meta.env.VITE_API_HOST)}/WafCaptcha`,
    config: {
      method: 'post',
      body: content
    }
  })

  // 发起请求
  const result = await fetchCaptcha(content)
  if (result?.status === 200) location.reload()
}

const Captcha: React.FC<Props> = observer((props: Props) => {
  const { params, callback } = props
  let reloadNum = 0 // 重新加载次数
  window.verifiCode = function (res: any) {
    const isAppCaptcha = /\/sams\/waf/.test(location.href)
    // ret 0 成功 2 用户关闭验证码  99 暂定为js加载失败
    if (res.ret === 0) {
      if (isAppCaptcha) {
        // 验证成功 给app 传递成功的信息
        sr.app.invoke('captchaResult', {
          randstr: res.randstr,
          ticket: res.ticket,
          seqid: params?.seqid || '',
          isSuccess: true
        })
      } else {
        checkCaptcha(res, params?.seqid)
      }
    } else if (res.ret === 2) {
      // 用户主动关闭验证码 关闭webview
      sr.app.invoke('closeWindow')

      Toast.fail('请进行验证')
    } else if (res.ret === 99 && res.errorCode === 1001) {
      aegis.report({
        event: 'captcha-load',
        businessType: 'captcha-fail',
        err: res,
        errorMsg: res?.msg || res?.message || '验证码Js加载失败,滑块验证无法拉起'
      })
      // js加载失败 定义
      sr.app.invoke('captchaResult', { isSuccess: false })
      Toast.fail(res?.msg || res?.message || '验证码Js加载失败,滑块验证无法拉起')
    } else {
      // 其他错误 上报失败
      aegis.report({
        event: 'captcha-load',
        businessType: 'captcha-fail',
        err: res,
        errorMsg: '滑块验证失败'
      })
      sr.app.invoke('captchaResult', { isSuccess: false })
      Toast.fail(res.errorMessage || '滑块验证失败')
    }
  }

  const loadErrorBack = () => {
    const CaptchaAppId = ''
    /* 生成票据或自行做其它处理 */
    const ticket = 'terror_1001_' + CaptchaAppId + Math.floor(new Date().getTime() / 1000)
    window.verifiCode({
      ret: 99,
      randstr: '@' + Math.random().toString(36).substr(2),
      ticket: ticket,
      errorCode: 1001,
      errorMessage: 'jsload_error'
    })
  }

  // js 加载完成回调
  const loadCallback = () => {
    const { TencentCaptcha } = window
    try {
      const current = new TencentCaptcha(params.id, window.verifiCode, {
        needFeedBack: false
      })
      current.show()
    } catch (error) {
      // 异常错误后弹窗
      loadErrorBack()
    }
  }

  // 动态加载验证码js
  const loadCaptchaJs = (callback: Function) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'

    script.onload = function () {
      // 加载成功 执行验证码图形
      callback()
    }

    script.onerror = function () {
      // 加载失败 重新发起loadJs
      reloadNum += 1
      // 加载失败达到次数限制 给终端抛出抛出异常
      reloadNum < 5 ? loadCaptchaJs(loadCallback) : loadErrorBack()
    }

    script.src = `${import.meta.env.VITE_CAPTCHA_HOST}`
    document.getElementsByTagName('body')[0].appendChild(script)
  }

  // 进入页面调用
  useEffect(() => {
    document.getElementsByTagName('html')[0].style.background = 'transparent'
    // 动态加载js 在load完成后再执行验证码
    loadCaptchaJs(loadCallback)
  }, [])

  return <div />
})

Captcha.show = function (params) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  ReactDOM.render(<Captcha params={params} />, div)
}

export default Captcha
