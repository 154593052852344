import React from 'react'
import ReactDOM from 'react-dom'
import JsBridge from '@/common/jsBridge'
import { isInApp, isInMiniProgram, urlParse, compareVersion, isLocalDebug } from '@/utils'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import '@/styles/global.less'
import routes from '@/routes'
import { Provider } from 'mobx-react'
import { store } from './store'
import { wxAppTrack } from '@/common/mall-tracking'
import './common/i18n'
import ErrorBoundary from './components/ErrorBoundary'
import { updateAegisConfig, aegis } from '@/common/aegis'
import Tversion from '@/components/Tversion/Tversion'
import '@tencent/tea-component-mobile/dist/tea.css'
import 'intersection-observer'
import { ComponentProvider } from '@tencent/tea-component-mobile'
import { initTongDun } from './utils/tongdun'
import { browseWxappPage } from '@/common/mall-tracking/index'
import dayjs from 'dayjs'
import session from '@/common/session'
import '@/utils/initInMiniProgram'
import duration from 'dayjs/plugin/duration'
import MemberTaskTimer from '@/components/Member-task-timer'
import { useAddressCode } from './hooks/use-address-code'

// 加载插件
dayjs.extend(duration)

// jsBridge设置
if (isInApp()) {
  const jsBridge = new JsBridge({
    namespace: 'sr',
    iosBridgeName: 'jsBridge',
    androidBridgeName: 'jsApi',
    callbackNamespace: 'callback',
    methods: [
      'locationInfo',
      'setTitle',
      'closeWindow',
      'openWindow',
      'login',
      'showLoading',
      'getPushStatus',
      'getAppData',
      'wechatPay',
      'showAgainRefresh', // 回到H5页面后刷新页面
      'pullRefreshByH5',
      'setNavigationRightItem',
      'refreshList',
      'viewInBrowser',
      'jumpToWechat',
      'bindMember',
      'configShareInfo',
      'captchaResult', // 验证码消息回调
      'sharePoster',
      'commonPay',
      'setTitleBar',
      'customerService', // 调用客服窗口
      'logoutSuccessful', // 账号注销成功回调
      'customBackAction',
      'getDeliveryAddressLocation', // 获取首页收货地址的经纬度数据
      'addReferPage',
      'wechatSign', // 纯签约
      'nativePropertiesForH5', // 终端埋点
      'jumpToMap', // 导航地图
      'callUp', // 拨打电话
      'getIdCard', // ocr 身份证
      'addAbtInfo', // 上报abtest配置信息通知终端
      'replaceUploadPhoto', // 更换照片
      'authenticationUploadPhoto', // 身份认证
      'propertiesForQYWebSDK', // 网易七鱼客服
      'showToast',
      'showImage',
      'callClientServiceModal' // 鸿蒙调用系统电话弹窗
    ],
    context: window
  })

  jsBridge.init()
  /** 注册页面离开事件 */
  jsBridge._callback.onLeavePage = (json: string) => {
    console.log('onLeavePage', dayjs().format())
    window.dispatchEvent(new Event('onLeavePage'))
  }
  /** 注册页面重新浏览事件 解决项目告警问题 */
  jsBridge._callback.onBrowsePageByBack = () => {
    console.log('onBrowsePageByBack', dayjs().format())
    try {
      // 返回修改浏览埋点公参
      const cachedValue = JSON.parse(sessionStorage.getItem('PAGE_BURIED_POINT_DATA') || '{}')
      if (typeof cachedValue === 'object' && Object.keys(cachedValue).length > 0) {
        const { props, extProps } = cachedValue
        props.is_newly_open = false
        browseWxappPage(props, extProps)
      }
    } catch (error) {
      console.error(error)
    }
    window.dispatchEvent(new Event('onBrowsePageByBack'))
  }
  /** 注册分享事件的埋点 */
  jsBridge._callback.onSharePage = (json: string) => {
    try {
      console.log('app传给H5的分享参数(json)：', json)
      const shareInfo = JSON.parse(json)
      console.log('app传给H5的分享参数(object)：', shareInfo)
      const callbackEvent = new CustomEvent('onShare', {
        detail: shareInfo
      })
      window.dispatchEvent(callbackEvent)
    } catch (error) {
      console.error('app传递的json不合法', error)
    }
  }

  /** 终端点击“一键生成图片”或者“保存图片”的回调 */
  jsBridge._callback.onClickShareImage = (json: string) => {
    try {
      console.log('onClickShareImage参数(json)：', json)
      const shareImageInfo = JSON.parse(json)
      console.log('onClickShareImage参数(object)：', shareImageInfo)
      // 202212月终端加上cancel事件  这里需要兼容 不然点击取消也会反复弹窗
      // if (shareImageInfo.clickType === 'cancel') {
      //   return
      // }

      const callbackEvent = new CustomEvent('onShare', {
        detail: shareImageInfo
      })
      window.dispatchEvent(callbackEvent)
    } catch (error) {
      console.error('app传递的json不合法', error)
    }
  }

  /** 点击导航栏分享按钮的回调 */
  jsBridge._callback.onClickNavShareBtn = () => {
    console.log('点击导航栏分享按钮')
    window.dispatchEvent(new Event('onClickNavShareBtn'))
  }
  /** 注册页面关闭的回调 */
  jsBridge._callback.onCloseWindow = () => {
    console.log('关闭窗口事件')
    window.dispatchEvent(new Event('onCloseWindow'))
  }
}

setTimeout(() => {
  const {
    chanCustomize = 'default',
    pmId = 'default',
    pushId = 'default',
    apppushmsgtaskid = 'default',
    systemmsgtasksubid = 'default',
    shortMessageTaskId = 'default',
    // share_user_id = 'default',
    // share_to = 'default',
    // share_platform = 'default',
    shortTemplateNumber = 'default',
    chan_id = undefined,
    channel
  } = urlParse()
  // 埋点
  wxAppTrack.appOnLaungh()
  const chan: any = {
    chanCustomize: chanCustomize,
    pushId: pushId,
    apppushmsgtaskid: apppushmsgtaskid,
    pmId: pmId,
    systemmsgtasksubid: systemmsgtasksubid,
    shortMessageTaskId: shortMessageTaskId,
    shortTemplateNumber: shortTemplateNumber,
    channel
  }
  if (chan_id) {
    // chan_id没有时 不用上报default
    chan.chanId = chan_id
  }
  // 外部跳链进入获取参数设置埋点数据
  wxAppTrack.setChan(chan)
  //  https://tapd.tencent.com/20418442/bugtrace/bugs/view/1120418442121468746 注释掉这个公参
  wxAppTrack.setUser({
    share_user_id: undefined,
    share_to: undefined,
    share_platform: undefined
  })
}, 0)

updateAegisConfig()

// 初始化同盾sdk
initTongDun()

const Router: any =
  import.meta.env.BASE_URL !== `${import.meta.env.VITE_STATIC_CDN}/` &&
  import.meta.env.BASE_URL !== '/'
    ? HashRouter
    : BrowserRouter

const appVersion = session.getAppVersion()
const tversion = session.getTversion()
if (isLocalDebug()) {
  aegis.destroy()
}

const App = () => {
  useAddressCode()
  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <ComponentProvider>{renderRoutes(routes)}</ComponentProvider>
          {/* 会员任务的倒计时浮层 */}
          <MemberTaskTimer />
        </ErrorBoundary>
      </Router>
      {import.meta.env.MODE === 'qa' ? <Tversion /> : null}
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
