// 插入校验头
import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'

const transUrlApiList = [
  '/v1/sams/sams-user/membership/trans/renew', // 续费升级
  '/v1/sams/sams-user/contract/alone_sign', // 独立签约
  '/v1/sams/sams-user/membership/trans/transferMainCard', // 转主卡
  '/v1/sams/sams-user/membership/trans/buy', // 购买会籍
  '/v1/sams/sams-user/redeem-code/activate', // 兑换码
  '/v1/sams/sams-user/membership/bind_mem_code_new', //  绑定会员卡
  '/v1/sams/trade/cart/addCartGoodsInfo', // 加车
  '/v3/sams/trade/settlement/commitPay', // 结算支付
  '/v1/sams/sams-user/wishcard/buy' // 购买心意卡
]

/** 注入渠道信息 */
export default function trackInfoHandler(): IInterceptor {
  return {
    name: 'trackInfoHandler',
    request: async (request) => {
      if (request.url && transUrlApiList.includes(request.url)) {
        const { trackInfo: trackInfoStr = '' } = await getAppDataByBridge(['trackInfo'])
        if (!trackInfoStr) return request
        const trackInfo = decodeURIComponent(trackInfoStr)
        if (!request.body) {
          request.body = {}
        }
        request.headers['track-info'] = trackInfo
      }
      return request
    }
  }
}
