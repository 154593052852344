import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'
import { LAN_KEY } from '@/config'
import { compareVersion } from '@/utils'
import moneyCalEnTrans from '@/pages/moneySaveCalc/locale/EN.json'
import moneyCalCnTrans from '@/pages/moneySaveCalc/locale/CN.json'

import savingPlanEnTrans from '@/pages/savingPlan/locale/EN.json'
import savingPlanCnTrans from '@/pages/savingPlan/locale/CN.json'

import invoiceOrderListEnTrans from '@/pages/invoiceOrderList/locale/EN.json'
import invoiceOrderListCnTrans from '@/pages/invoiceOrderList/locale/CN.json'

import onlineCardRefundEnTrans from '@/pages/onlineCardRefund/locale/EN.json'
import onlineCardRefundCnTrans from '@/pages/onlineCardRefund/locale/CN.json'

import fillInvoiceDetailEnTrans from '@/pages/fillInvoiceDetail/locale/EN.json'
import fillInvoiceDetailCnTrans from '@/pages/fillInvoiceDetail/locale/CN.json'

import autorenewAgreementEnTrans from '@/pages/autorenewAgreement/locale/EN.json'
import autorenewAgreementCnTrans from '@/pages/autorenewAgreement/locale/CN.json'

import saveMoneyRuleEnTrans from '@/pages/saveMoneyRule/locale/EN.json'
import saveMoneyRuleCnTrans from '@/pages/saveMoneyRule/locale/CN.json'

import memberTaskEnTrans from '@/pages/memberTask/locale/EN.json'
import memberTaskCnTrans from '@/pages/memberTask/locale/CN.json'

import equityCenterEnTrans from '@/pages/equityCenter/locale/EN.json'
import equityCenterCnTrans from '@/pages/equityCenter/locale/CN.json'

import pinganEnTrans from '@/pages/advert/pingan/locale/EN.json'
import pinganCnTrans from '@/pages/advert/pingan/locale/CN.json'

import renewCnTrans from '@/components/RenewComponent/locale/CN.json'
import renewEnTrans from '@/components/RenewComponent/locale/EN.json'

import cancellationEnTrans from '@/pages/cancellation/locale/EN.json'
import cancellationCnTrans from '@/pages/cancellation/locale/CN.json'

import experiencePromoteEnTrans from '@/pages/experiencePromote/locale/EN.json'
import experiencePromoteCnTrans from '@/pages/experiencePromote/locale/CN.json'

import pointEnTrans from '@/pages/pointQuery/locale/EN.json'
import pointCnTrans from '@/pages/pointQuery/locale/CN.json'

import licensePageEnTrans from '@/pages/licensePage/locale/EN.json'
import licensePageCnTrans from '@/pages/licensePage/locale/CN.json'

import autoRenewEn from '@/pages/autoRenew/locale/EN.json'
import autoRenewCn from '@/pages/autoRenew/locale/CN.json'

import enterpriseProcurementEnTrans from '@/pages/enterpriseProcurement/locale/EN.json'
import enterpriseProcurementCnTrans from '@/pages/enterpriseProcurement/locale/CN.json'

import renewPageEnTrans from '@/pages/renew/locale/EN.json'
import renewPageCnTrans from '@/pages/renew/locale/CN.json'

import maotaiEnTrans from '@/pages/activity/maotai/locale/EN.json'
import maotaiCnTrans from '@/pages/activity/maotai/locale/CN.json'

import renewV2PageEnTrans from '@/pages/renewV2/locale/EN.json'
import renewV2PageCnTrans from '@/pages/renewV2/locale/CN.json'

import certificationCnTrans from '@/pages/certifiMember/locale/CN.json'
import certificationEnTrans from '@/pages/certifiMember/locale/EN.json'

import equityCompareEnTrans from '@/pages/equityCompare/locale/EN.json'
import equityCompareCnTrans from '@/pages/equityCompare/locale/CN.json'

import changeAvatarEnTrans from '@/pages/changeAvatar/locale/EN.json'
import changeAvatarCnTrans from '@/pages/changeAvatar/locale/CN.json'

import identityAuthenticationEnTrans from '@/pages/identityAuthentication/locale/EN.json'
import identityAuthenticationCnTrans from '@/pages/identityAuthentication/locale/CN.json'

import authenticationDetailEnTrans from '@/pages/authenticationDetail/locale/EN.json'
import authenticationDetailCnTrans from '@/pages/authenticationDetail/locale/CN.json'

import selfPickupMapEnTrans from '@/pages/selfPickupMap/locale/EN.json'
import selfPickupMapCnTrans from '@/pages/selfPickupMap/locale/CN.json'
import selfPickupMapTcTrans from '@/pages/selfPickupMap/locale/TC.json'

import freightDescEnTrans from '@/pages/freightDesc/locale/EN.json'
import freightDescCnTrans from '@/pages/freightDesc/locale/CN.json'
import freightDescTcTrans from '@/pages/freightDesc/locale/TC.json'

import equityOfflineDetailsEnTrans from '@/pages/equityOfflineDetails/locale/EN.json'
import equityOfflineDetailsCnTrans from '@/pages/equityOfflineDetails/locale/CN.json'
import cmbcEnTrans from '@/pages/advert/cmbc/locale/EN.json'
import cmbcCnTrans from '@/pages/advert/cmbc/locale/CN.json'

import memberGuideCnTrans from '@/pages/memberGuidePage/locale/CN.json'
import memberGuideEnTrans from '@/pages/memberGuidePage/locale/EN.json'

import session from '@/common/session'
const appVersion = session.getAppVersion()
const tversion = session.getTversion()
const isReward = appVersion?.includes('5.0.102') || tversion.includes('reward')

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: {
          welcome: 'welcome',
          tips1: 'I have read and agree to',
          tips2: 'Please check the relevant terms and agreements',
          tips3: 'and',
          agreement1: 'the personal information terms of use',
          agreement2: '<Privacy policy>',
          agreement3: "Sam's Club Membership Policy",
          agreement4: 'Terms of Use on Personal Photo.',
          effective: 'During the validity',
          failure: 'Has expired',
          freeze: 'Frozen',
          expiringSoon: 'About to Expire',
          expired: 'Expired',
          linkFailureTips: 'Please contact the customer service if any inguiny'
        },
        moneyCalculator: moneyCalEnTrans,
        savingPlan: savingPlanEnTrans,
        invoiceOrderList: invoiceOrderListEnTrans,
        onlineCardRefund: onlineCardRefundEnTrans,
        autorenewAgreement: autorenewAgreementEnTrans,
        saveMoneyRule: saveMoneyRuleEnTrans,
        memberTask: memberTaskEnTrans,
        fillInvoiceDetail: fillInvoiceDetailEnTrans,
        equityCenter: equityCenterEnTrans,
        pinganAdvert: pinganEnTrans,
        renewComponent: renewEnTrans,
        cancellation: cancellationEnTrans,
        experiencePromote: experiencePromoteEnTrans,
        pointDetail: pointEnTrans,
        licensePage: licensePageEnTrans,
        autoRenew: autoRenewEn,
        enterpriseProcurement: enterpriseProcurementEnTrans,
        renew: renewPageEnTrans,
        maotai: maotaiEnTrans,
        renewV2: renewV2PageEnTrans,
        certifiMember: certificationEnTrans,
        equityCompare: equityCompareEnTrans,
        changeAvatar: changeAvatarEnTrans,
        identityAuthentication: identityAuthenticationEnTrans,
        authDetail: authenticationDetailEnTrans,
        selfPickupMap: selfPickupMapEnTrans,
        freightDesc: freightDescEnTrans,
        cmbcAdvert: cmbcEnTrans,
        memberGuide: memberGuideEnTrans,
        equityOfflineDetails: equityOfflineDetailsEnTrans
      },
      zh: {
        common: {
          welcome: '欢迎',
          tips1: '我已阅读并同意',
          tips2: '请勾选相关条款及协议',
          tips3: '及',
          agreement1: '《个人证件信息使用条款》',
          agreement2: '《隐私政策》',
          agreement3: '《山姆会员商店会员章程》',
          agreement4: '《个人照片使用条款》',
          agreement5: '《用户服务协议》',
          effective: '有效期内',
          failure: '已失效',
          freeze: '已冻结',
          expiringSoon: '即将过期',
          expired: '已过期',
          linkFailureTips: '该分享已失效，请与您的亲友联系，或直接购买会籍'
        },
        moneyCalculator: moneyCalCnTrans,
        savingPlan: savingPlanCnTrans,
        invoiceOrderList: invoiceOrderListCnTrans,
        onlineCardRefund: onlineCardRefundCnTrans,
        autorenewAgreement: autorenewAgreementCnTrans,
        saveMoneyRule: saveMoneyRuleCnTrans,
        memberTask: memberTaskCnTrans,
        fillInvoiceDetail: fillInvoiceDetailCnTrans,
        equityCenter: equityCenterCnTrans,
        pinganAdvert: pinganCnTrans,
        renewComponent: renewCnTrans,
        cancellation: cancellationCnTrans,
        experiencePromote: experiencePromoteCnTrans,
        pointDetail: pointCnTrans,
        licensePage: licensePageCnTrans,
        autoRenew: autoRenewCn,
        enterpriseProcurement: enterpriseProcurementCnTrans,
        renew: renewPageCnTrans,
        maotai: maotaiCnTrans,
        renewV2: renewV2PageCnTrans,
        certifiMember: certificationCnTrans,
        equityCompare: equityCompareCnTrans,
        changeAvatar: changeAvatarCnTrans,
        identityAuthentication: identityAuthenticationCnTrans,
        authDetail: authenticationDetailCnTrans,
        selfPickupMap: selfPickupMapCnTrans,
        freightDesc: freightDescCnTrans,
        cmbcAdvert: cmbcCnTrans,
        memberGuide: memberGuideCnTrans,
        equityOfflineDetails: equityOfflineDetailsCnTrans
      },
      tc: {
        selfPickupMap: selfPickupMapTcTrans,
        freightDesc: freightDescTcTrans,
        cmbcAdvert: cmbcCnTrans
      }
    },
    lng: 'zh',
    keySeparator: '',
    interpolation: {
      escapeValue: false
    }
  })

const languageMap: { [key: string]: string } = {
  CN: 'zh',
  EN: 'en'
}
const lng = Cookies.get(LAN_KEY) || 'CN'
if (languageMap[lng]) {
  i18n.changeLanguage(languageMap[lng])
}
