import { update } from 'lodash-es'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'
import { makeAutoObservable, runInAction } from 'mobx'
import Session from '@/common/session'
import { apis } from '@/common/http-client'
import { isInApp } from '@/utils'
import { staticBaseUrl } from '@/config'

const uid = Session.getUid()

// 活动状态
export enum ActivityStatusEnum {
  WAIT_PUBLISH = 1, // 待发布
  UN_START = 2, // 未开始
  UNDER_WAY = 3, // 进行中
  HAVE_END = 4, // 已结束
  HAVE_EXPIRED = 5 // 已过期
}
export const ActivityStatusText: Record<number, string> = {
  [ActivityStatusEnum.UN_START]: '活动未开始,敬请关注',
  [ActivityStatusEnum.HAVE_END]: '活动已结束,感谢您的参与'
}
const RESPONSE_CODE = {
  SUCCESS: 'Success',
  ACTIVITY_HAS_END: 'ACTIVITY_HAS_END',
  ACTIVITY_HAS_NOT_STARTED: 'ACTIVITY_HAS_NOT_STARTED',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  INTERNAL_ERROR: 'INTERNAL_ERROR'
}
class NewShopStore {
  channel = ''
  isMember = 0 // 是否会员 0：否 1：是
  cardStatus = 0 // 会员卡状态 1: 有效期内，2：即将过期，3：已过期，4：已失效，5：已冻结
  blackFlag = false // 是否黑名单 true：否 false：是
  isExperienceCard = 0 // 是否体验卡 0：否 1：是
  isLoading = true // 是否加载中
  isReachBottom = false // 是否到底部
  shareInfo = { eventName: 'default', formType: 'button' } // 分享信息
  openPrizeModalVisible = false // 是否显示中奖弹窗
  myShareModalVisible = false // 是否显示我的分享弹窗
  activityType = 34 // 34为新店推广活动
  lotteryOpportunity = 0 // 是否具备抽奖资格，0：未获得，1：已获得
  lotteryResultsPublish = 0 // 是否已开奖，0：未开奖，1：已开奖
  lotteryResults = 0 // 抽奖结果，如果lotteryResultsPublish等于1则用到此字段，0：未中奖，1：已中奖
  activityStatus = 0 // 0-默认状态、1-待发布、2-未开始、3-进行中、4-已结束、5-已过期
  isRequestIng = true // 活动接口是否请求中
  isShowMemberPage = false // 是否显示会员页面
  btnIsLineStyle = false // 按钮是否为线框样式
  activityId = '' // 活动id
  cardNo = '' // 会员卡号

  constructor() {
    makeAutoObservable(this)
  }

  async updateTrackInfo() {
    try {
      const { trackInfo: jsbTrackInfo = '' } = await getAppDataByBridge(['trackInfo'])
      const trackInfo = JSON.parse(decodeURIComponent(jsbTrackInfo) || '[]')
      this.channel =
        trackInfo.filter((item: { labelType: string }) => item.labelType === 'tracepromotion')[0]
          ?.attachId || 'default'
    } catch (e) {
      console.log(e)
    }
  }

  scrollToBottom() {
    try {
      window.scrollTo({
        top: document.documentElement.scrollHeight - window.innerHeight,
        behavior: 'smooth'
      })
    } catch (e) {
      const start = window.scrollY
      const end = document.documentElement.scrollHeight - window.innerHeight
      const duration = 1000
      const startTime = performance.now()
      const easeInOutQuad = (t: number) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
      }
      const scrollStep = (timestamp: number) => {
        const elapsed = timestamp - startTime
        const progress = Math.min(elapsed / duration, 1)
        const easedProgress = easeInOutQuad(progress)
        const currentScroll = start + (end - start) * easedProgress
        window.scrollTo(0, currentScroll)
        if (progress < 1) {
          requestAnimationFrame(scrollStep)
        }
      }
      requestAnimationFrame(scrollStep)
    }
  }
  async getShareId(shareKey = 'eventShareId') {
    const { UUID = '' } = await getAppDataByBridge(['UUID'])
    const now = new Date().getTime()
    return UUID ? `${shareKey}=${now}_${UUID}` : `${shareKey}=''`
  }

  async shareNewStoreFun() {
    if (isInApp()) {
      const sharePic = `${staticBaseUrl}newStoreActivity/shre-img-v2.png`
      const shareId = await this.getShareId()
      const url = `pages/newStoreActivity/index?share_user_id=${uid}&share_to=friends&share_platform=app&share_time=${new Date().valueOf()}&channel=${
        this.channel
      }&${shareId}&activityId=${this.activityId}`
      // 会员分享需要带上分享人的信息
      const shareUrl = this.isShowMemberPage
        ? `${url}&recommendUid=${uid}&recommendMemCode=${this.cardNo}&recommendChannel=3`
        : url
      sr.app.invoke('configShareInfo', {
        shareTitle: '武汉江岸山姆即将开业！',
        shareUrl: shareUrl,
        shareType: 'miniProgram',
        miniProgramPath: shareUrl, // 小程序链接
        sharePic: sharePic,
        showFriendsCircle: false,
        showCreateImage: false,
        invokeShare: true
      })
    }
  }

  getMemberInfoFun = async () => {
    try {
      const res = await apis.cancellation.getUserPhone()
      const { code, data = {} } = res

      runInAction(() => {
        this.isLoading = false
        if (code === 'Success') {
          this.isMember = data?.isMember
          this.isExperienceCard = data?.isExperienceCard
          this.cardStatus = data?.cardStatus
          this.blackFlag = data?.blackFlag
          this.cardNo = data?.cardNo || ''
          if (
            data?.isExperienceCard !== 1 &&
            Number(data?.isMember) === 1 &&
            !data.blackFlag &&
            [1, 2].includes(data?.cardStatus)
          ) {
            this.isShowMemberPage = true
          }
        }
      })
    } catch (error: any) {
      runInAction(() => {
        this.isLoading = false
      })
      console.log(error.message)
    }
  }

  getActivityDetailFun = async (id: string) => {
    try {
      const res = await apis.newStore.getActivityDetail({ activityId: id })
      const { code, data = {} } = res
      const {
        activityType,
        lotteryOpportunity = 0,
        lotteryResults = 0,
        lotteryResultsPublish = 0
      } = data || {}
      runInAction(() => {
        this.isRequestIng = false
        this.activityId = id
        if (code === RESPONSE_CODE.SUCCESS) {
          this.activityType = activityType
          this.lotteryOpportunity = lotteryOpportunity
          this.lotteryResults = lotteryResults
          this.lotteryResultsPublish = lotteryResultsPublish
          const isNewStoreToolPrizeShowId = localStorage.getItem('isNewStoreToolPrizeShow_id')

          if (
            id !== isNewStoreToolPrizeShowId &&
            Number(lotteryOpportunity === 1) &&
            Number(lotteryResultsPublish) === 1
          ) {
            this.openPrizeModalVisible = true
          }
        } else if (code === RESPONSE_CODE.ACTIVITY_HAS_END) {
          this.activityStatus = ActivityStatusEnum.HAVE_END
        } else if (code === RESPONSE_CODE.ACTIVITY_HAS_NOT_STARTED) {
          this.activityStatus = ActivityStatusEnum.UN_START
        }
      })
    } catch (error: any) {
      runInAction(() => {
        this.isRequestIng = false
      })
      console.log(error.message)
    }
  }

  getActivitySimpleInfo = async (id: string) => {
    try {
      const res = await apis.newStore.getActivitySimpleInfo({ activityId: id })
      const { code, data = {} } = res
      const { activityStatus } = data || {}
      runInAction(() => {
        this.isRequestIng = false
        this.activityId = id
        if (code === RESPONSE_CODE.SUCCESS) {
          this.activityStatus = activityStatus
        }
      })
    } catch (error: any) {
      runInAction(() => {
        this.isRequestIng = false
      })
      console.log(error.message)
    }
  }

  updateIsReachBottom(value: boolean) {
    this.isReachBottom = value
  }

  updateShareInfo(value: { eventName: string; formType: string }) {
    this.shareInfo = value
  }

  updateMyShareModalVisible(value: boolean) {
    this.myShareModalVisible = value
  }

  updateOpenPrizeModalVisible(value: boolean) {
    this.openPrizeModalVisible = value
  }

  updateBtnIsLineStyle(value: boolean) {
    this.btnIsLineStyle = value
  }
}
export default new NewShopStore()
