import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import Captcha from '@/components/Captcha/WafCaptcha'

export default function genInterceptor(): IInterceptor {
  return {
    name: 'highriskHandler',
    response: async (res) => {
      const bodyStr = String(res.data)
      const sig_data = /seqid\s=\s"(\w+)"/g

      const waf_id_data = /TencentCaptcha\(\'(\d+)\'/g

      const seqid_matches = sig_data.exec(bodyStr)
      const wid_matches = waf_id_data.exec(bodyStr)

      if (seqid_matches?.[1] && wid_matches?.[1]) {
        Captcha.show({
          seqid: seqid_matches[1],
          id: wid_matches[1]
        })
        throw res
      }

      return res.data
    }
  }
}
