import { aegis } from '@/common/aegis'
import React, { Suspense } from 'react'

export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  state: any = {}

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    aegis.error(new Error(`${JSON.stringify(error)}-${JSON.stringify(errorInfo)}`))
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>some things went wrong...</h1>
    }

    return <Suspense fallback={<div />}>{this.props.children}</Suspense>
  }
}
